import React, { useEffect } from "react"
import Img from "gatsby-image"

const Process = ({ fluid, lang }) => {
  const { title, items } = lang

  useEffect(() => {}, [])

  return (
    <section className="h-750 flex items-center">
      <div
        className="h-750 mx-auto left-0 right-0 absolute"
        style={{ zIndex: "-10" }}
      >
        <Img className="w-full h-full bg-black" fluid={fluid} loading="eager" />
      </div>
      <div className="bg-white p-6 text-center max-w-750 mx-auto relative">
        <h1 className="heading">{title}</h1>
        <p className="m-0 body mt-6">{items[0]}</p>
        <div className="h-8 w-2 bg-lightGreen mx-auto my-4" />
        <p className="m-0 body">{items[1]}</p>
        <div className="h-8 w-2 bg-lightGreen mx-auto my-4" />
        <p className="m-0 body">{items[2]}</p>
        <div className="h-16 w-2 bg-lightGreen mx-auto my-4" />
        <div className="h-8 w-2 bg-lightGreen mx-auto my-4 transform rotate-45 absolute left-0 right-0 translate-x-2 -translate-y-14" />
        <div className="h-8 w-2 bg-lightGreen mx-auto my-4 transform -rotate-45 absolute left-0 right-0 -translate-x-2 -translate-y-14" />
      </div>
    </section>
  )
}

export default Process
