import React, { useEffect } from "react"
import { graphql } from "gatsby"

// components
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Landing from "../components/energy-modeling/landing"
import List from "../components/energy-modeling/list"
import Deliverables from "../components/energy-modeling/deliverables"
import Process from "../components/energy-modeling/process"
import CaseStudy from "../components/case-study"

const EnergyRetrofits = ({ data }) => {
  useEffect(() => {}, [])

  return (
    <Layout
      headerLang={data?.langQuery?.siteMetadata?.en?.header}
      footerLang={data?.langQuery?.siteMetadata?.en?.footer}
      langType="en"
    >
      <SEO
        title="Energy Modeling"
        description="EEE performs Energy Modeling Projects timely, gloabaly, and remotely."
      />
      <Landing
        fluid={data?.headerImage?.childImageSharp?.fluid}
        lang={data?.langQuery?.siteMetadata?.en?.modeling?.landing}
        langType="en"
      />
      <List lang={data?.langQuery?.siteMetadata?.en?.modeling?.list} />
      <div id="more-info">
        <Deliverables
          miami={data?.miami?.childImageSharp?.fluid}
          colombia={data?.colombia?.childImageSharp?.fluid}
          chicago={data?.chicago?.childImageSharp?.fluid}
          equador={data?.equador?.childImageSharp?.fluid}
          ethiopia={data?.ethiopia?.childImageSharp?.fluid}
          mexico={data?.mexico?.childImageSharp?.fluid}
          saudiarabia={data?.saudiarabia?.childImageSharp?.fluid}
          puertorico={data?.puertorico?.childImageSharp?.fluid}
          lang={data?.langQuery?.siteMetadata?.en?.modeling?.deliverables}
        />
        <Process
          fluid={data?.processImage?.childImageSharp?.fluid}
          lang={data?.langQuery?.siteMetadata?.en?.modeling?.process}
        />
        <CaseStudy data={data} />
      </div>
    </Layout>
  )
}

export default EnergyRetrofits

export const query = graphql`
  query {
    headerImage: file(
      relativePath: { eq: "you-x-ventures-vbxyFxlgpjM-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    processImage: file(
      relativePath: { eq: "toa-heftiba-4xe-yVFJCvw-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    miami: file(relativePath: { eq: "ryan-parker-mqpawAQXRyA-unsplash.webp" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    colombia: file(
      relativePath: { eq: "fausto-daniel-moya-xwv44fFXtrw-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chicago: file(
      relativePath: { eq: "pedro-lastra-Nyvq2juw4_o-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    equador: file(
      relativePath: { eq: "jaime-dantas-JcJrM4EqR00-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ethiopia: file(
      relativePath: { eq: "gift-habeshaw-ErteILhW2lc-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mexico: file(
      relativePath: { eq: "roman-lopez-_xEHhGga2nY-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    saudiarabia: file(
      relativePath: { eq: "mohammed-hassan-uRf0HVGw-3E-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    puertorico: file(
      relativePath: { eq: "alex-george--JAwGkwd2UM-unsplash.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    langQuery: site {
      siteMetadata {
        en {
          header {
            commissioning
            modeling
            retrofits
            cfd
            projects
            contact
          }
          footer {
            contact
            navItems
          }
          modeling {
            landing {
              title
              body
            }
            list {
              title
              list
            }
            deliverables {
              title
              body
              items
            }
            process {
              title
              items
            }
          }
        }
      }
    }
    allContentfulFeaturedProject(
      filter: {
        service: {
          name: { eq: "Energy Modeling" }
          node_locale: { eq: "en-US" }
        }
      }
    ) {
      edges {
        node {
          id
          name
          summary {
            summary
          }
          previewImage {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            description
          }
        }
      }
    }
  }
`
