import React from "react"

// componants
import ImgCard from "../layout/imgCard"

export default function Deliverables({
  miami,
  colombia,
  chicago,
  equador,
  ethiopia,
  mexico,
  saudiarabia,
  puertorico,
  lang,
}) {
  const { title, body, items } = lang

  return (
    <section className="w-full mt-10 pt-16 pb-24 bg-gray-100">
      <div className="max-w-1024 px-2x mx-auto">
        <div className="text-center max-w-600 mx-auto">
          <h1 className="heading">{title}</h1>
          <p className="body mt-4">{body}</p>
        </div>
        <div className="flex justify-around mt-10">
          <ImgCard title={items[0]} src={miami} />
          <ImgCard title={items[1]} src={colombia} />
          <ImgCard title={items[2]} src={chicago} />
        </div>

        <div className="flex justify-around mt-14">
          <ImgCard title={items[4]} src={ethiopia} />
          <ImgCard title={items[5]} src={mexico} />
          <ImgCard title={items[7]} src={puertorico} />
        </div>
      </div>
    </section>
  )
}
