import React, { useEffect } from "react"
import Img from "gatsby-image"

// components
import HeaderBtn from "../layout/headerButtons"

const Landing = ({ fluid, lang, langType }) => {
  const { title, body } = lang

  useEffect(() => {}, [])

  return (
    <section className="w-full h-600 flex items-center justify-center relative desktop:px-10 mt-20">
      <div
        className="h-600 mx-auto left-0 right-0 absolute"
        style={{ zIndex: "-10" }}
      >
        <Img className="w-full h-full bg-black" fluid={fluid} loading="eager" />
      </div>
      <div className="max-w-1024 desktop:px-2x flex justify-start w-full">
        <div className="bg-white desktop:p-8 mobile:p-4">
          <h1 className="heading">{title}</h1>
          <p className="body max-w-600 mt-6">{body}</p>
          <HeaderBtn
            blk={true}
            linkTo={
              langType === "en"
                ? "/energy-modeling/#more-info"
                : "/es/energy-modeling/#more-info"
            }
          />
        </div>
      </div>
    </section>
  )
}

export default Landing
