import React from "react"

export default function List({ lang }) {
  const { title, list } = lang
  return (
    <section className="w-full">
      <div className="max-w-1024 mx-auto px-2x pt-14 pb-10">
        <h2 className="heading text-2xl">{title}</h2>
        <ul className="m-0  mt-4">
          <li className="py-2 pl-10 bg-lightBlue body">
            <p>{list[0]}</p>
          </li>
          <li className="py-2 pl-10 body">
            <p>{list[1]}</p>
          </li>
          <li className="py-2 pl-10 bg-lightBlue body">
            <p>{list[2]}</p>
          </li>
          <li className="py-2 pl-10 body">
            <p>{list[3]}</p>
          </li>
          <li className="py-2 pl-10 bg-lightBlue body">
            <p>{list[4]}</p>
          </li>
          <li className="py-2 pl-10 body">
            <p>{list[5]}</p>
          </li>
          <li className="py-2 pl-10 bg-lightBlue body">
            <p>{list[6]}</p>
          </li>
        </ul>
      </div>
    </section>
  )
}
